.volume-container {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  position: absolute;
  bottom: 0.5rem;
  &.cart-volume {
    bottom: 0rem;
    right: 0.5rem;
  }
  &.selector-volume {
    @media (max-width: 575px) {
      bottom: 0.5rem;
      left: 1rem;
      border-radius: calc(0.25rem - 1px) 0.5rem calc(0.25rem - 1px) calc(0.25rem - 1px);
    }

    @media (min-width: 576px) {
      bottom: 0.5rem;
      right: 1rem;
    }
  }
  padding: 5px 10px 7px;
  background: $white;
  border: 1px solid #d7d7d7;
  border-radius: 0.5rem calc(0.25rem - 1px) calc(0.25rem - 1px) calc(0.25rem - 1px);
  max-width: none;
  

  .cup-container {
    display: flex;
    position: relative;
    margin-left: 3px;
    margin-right: 3px;
    overflow: hidden;
    padding-top: 5px;
    margin-bottom: 2px;

    &.full-cup {
      background: none;
      height: 20px;

      .filling-cup {
        bottom: -3px;
      }
    }

    .empty-cup {
      background: url('../../assets/images/cup.svg');
      background-repeat: no-repeat;
      width: 19px;
      height: 15px;
      display: inline-block;
      z-index: 5;
    }

    .filling-cup {
      background: url('../../assets/images/liquid.svg');
      background-repeat: no-repeat;
      width: 19px;
      height: 16px;
      display: inline-block;
      z-index: 3;
      position: absolute;
      bottom: -15px;
      left: 0;

      @include media-breakpoint-up(lg) {
        background: url('../../assets/images/liquid.svg');
        background-size: contain;
      }
    }

    .drop {
      background: url('../../assets/images/drop.svg');
      background-repeat: no-repeat;
      width: 7px;
      height: 10px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0 6px;
      z-index: 10;
    }
  }
}