.cormie-badge {
  color: black;
  font-weight: 300;
  transition: all 0.3s ease;

  &.pink {
    background-color: $cormie-pink-faded !important;
  }

  &.blue {
    background-color: #ECFBFC !important;
  }

  &.tag-ignore {
    opacity: 0.8;
    background-color: lightgrey !important;
  }

  &.hover {
    &:hover {
      transform: scale(1.1);
      transition: all 0s;
      cursor: pointer;
    }
  }

  &.hover-order {
    border: 1px solid $cormie-blue;
    box-shadow: 0 0 0 3px #fff inset;
    &:hover {
      transform: scale(1.1);
      transition: all 0s;
      cursor: pointer;
    }
  }

  &.hover-order-cancelled {
    border: 1px solid $cormie-pink;
    box-shadow: 0 0 0 3px #fff inset;
    &:hover {
      transform: scale(1.1);
      transition: all 0s;
      cursor: pointer;
    }
  }
}

.cormie-badge-icon {
  font-size: 0.8em;
}
