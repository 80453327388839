.mapped-profile-field {
  margin-bottom: 0.6rem;

  .col-sm-4 {
    @media (min-width: 576px) {
      max-width: 210px;
    }
  }
}

.input-field {
  background: transparent;
  border: none;
}

.invalid-warning {
  display: none;
  color: red
}

.invalid-warning.show {
  display: block;
}

h1 {
font-size: 1.7rem;
margin-bottom: 1.6rem;
}

@media (max-width: 768px) {
  
  .mfs {
   font-size: 1.1rem;
  }
  
}

@media (min-width: 576px) {
  .profile-input-field {
    padding-left: 0.5rem;
  }
}
.pause-link, .resume-link {
  transition: none;
  &:hover {
    opacity: 1.0;
  }
}

.pause-link {
  &:hover {
      color: $cormie-pink;
      text-decoration: underline;
      transition: none;
  }
}

.resume-link {
  color: $cormie-pink;
  &:hover {
      color: $cormie-blue;
      text-decoration: underline;
      transition: none;
  }
}

.floating-button-set {
  position: fixed;
  bottom: 6px;
  right: 20px;
  background-color: white;
  border-radius: 10px;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;

  .pink.main-button.button-with-solid-background {
    background-color: white;
    &:hover {
      opacity: 1.0;
      filter: brightness(120%);
    }
  }
  .blue.main-button {
    &:hover {
      opacity: 1.0;
      filter: brightness(120%);
    }
  }
}

.floating-button-set-buffer {
  min-height: 90px;
}

.hide-on-mobile {
  @media (max-width: 1280px) {
    display: none !important;
  }
}

.add-to-one-off-order-button {
  padding: 0px;
}
