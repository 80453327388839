.product-modal-card {
  transition: all 0.3s ease;
  border-width: 2px;
  &:hover {
    border: 2px solid pink;
    transition: all 0s;
  }

  .card-header {
    overflow: hidden;
  }

  .height-mobile-220{
      @media (max-width: 575px) {
        max-height: 220px;
        width:auto;
      }
  }
}

.max-width-210-prod-selector {
  max-width: 210px;
}
