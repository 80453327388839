thead > tr > th {
  padding: 1rem 0 !important;
}

tbody > tr > th {
  padding: 1rem 0 !important;
}

.search-container {
  background-color: white;
}

.search-bar-row {
  background-color: #ECFBFC;
  border: 3px solid #D2F9F5;
  position: relative;

  .search-input-wrapper {
    width: 100%;

      &:before {
        content: "";
        width: 4em;
        height: 80%;
        position: absolute;
        top: 6px;
        background: linear-gradient(to right, transparent, white 50%);
        right: 38px;
      }

    .search-bar-input {
      border-radius: 30px !important;
      border: 2px solid #60D9E7;
    }
  }

  .search-icon {
    font-size: 1.2em;
    color: $cormie-pink;
    position: absolute;
    top: 20px;
    right: 45px;
    z-index: 13;
  }
}

.result-num-wrapper {
  width: auto;

  .result-num-div {
    position: relative;

    &:after {
      content: '⌄';
      color: $cormie-blue;
      position: absolute;
      pointer-events: none;
      font-size: 21px;
      left: 96px;
      top: -3px;
    }

    .result-number-input {
      padding: 0.3rem;
      padding-left: 0.7rem;
      padding-right: 1.8rem;
      border-radius: 6px;
      border: #E6E6E6 solid;

      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
    }
  }
}

.pagination-select {
  color: $cormie-blue !important;
  a {
    color: $cormie-blue !important;
  }
  position: relative;
  cursor: pointer;

  &.disabled {
    cursor: default;
    color: black;
    opacity: 0.2;
  }

  &.next {
    &:after {
      content: "»";
      position: absolute;
      right: -14px;
      top: -4px;
      font-size: 20px;
    }

    .border-left {
      border-left: 1px solid black;
    }
  }
  &.previous {
    &:before {
      content: "«";
      position: absolute;
      left: -14px;
      top: -4px;
      font-size: 20px;
    }

    .border-right {
      border-right: 1px solid black;
    }
  }
}

.all-customers-div {
  width: auto;
}

.orders-table-client-name-cell {
  min-width: 170px;
}

.orders-table-order-number-cell {
  min-width: 140px;
}

.orders-table-order-date-cell {
  min-width: 190px;
}

.user-table-name-cell {
  min-width: 170px;
}

.user-table-tag-cell {
  max-width: 320px;
}

.user-table-phone-cell {
  min-width: 140px;
}

.user-table-address-cell {
  min-width: 180px;
}

.table-tag {
  // padding: 0.6rem 1rem 0.6rem 1rem !important;
  font-size: 0.8rem;
}
