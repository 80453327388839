.password-reset-text {
  font-weight: 300;
  color: $cormie-pink;
  &:hover {
    color: #e01007;
    text-decoration: underline;
    opacity: 1.0;
  }
}

.login-col {
  border-radius: 0.58824rem;
  border: 0.05882rem solid #e6e6e6;
  background-color: #fff;
}

.login-alert {
  color: #f9423a;
  background-color: #fdf6f9;
  font-size: 1.05882rem;
  text-transform: unset;
  margin-bottom: 1.17647rem;
  padding: 20px 12px 19px;
}