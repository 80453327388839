.size-and-quantity {
  margin-top: 1.1em;
  margin-bottom: 1.1rem !important; // For some reason, Bootstrap has this rule we need to override: .mb-2 {margin-bottom 0.5rem !important}
}

.size-dropdown {
  border-radius: 7px;

  select {
    letter-spacing: 1px;
    font-size: 0.9rem;
    text-transform: uppercase;
  }
}

.quantity {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #F5F5F5;
  border: 1px solid #ced4da;
  
  &.plus {
    border-radius: 0 0.25rem 0.25rem 0;
    border-left: none;
    cursor: pointer;
  }
  
  &.number {
    background-color: #fff;
    min-width: 2.5rem;
    text-align: center;
  }
  
  &.minus {
    border-radius: 0.25rem 0 0 0.25rem;
    border-right: none;
    cursor: pointer
  }
}