// Overrides the hidden <ol> component in the react bootstrap breadcrumb component
.breadcrumb {
  margin-bottom: 0 !important;
}

$breadcrumb-divider: quote(">");

.react-datepicker__close-icon::after {
  background-color: transparent !important;
  color: black !important;
  font-size: 30px !important;
}
