.title-links {
  white-space: nowrap;
  color: $cormie-pink;
  a:hover {
    color: #e01007;
    text-decoration: underline;
    opacity: 1.0;
  }
}

.border-cormie-pink {
  border-color: $cormie-pink !important;
}