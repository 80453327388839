.cormie-section {
  background-color: white;
  border-radius: 10px;
  padding-top: 0.7rem; 
  padding-left: 1rem; 
  padding-right: 1rem; 
  padding-bottom: 1rem; 

  form {
    padding: 0;
  }

  &.left {
    @include media-breakpoint-up(md) {
      margin-right: 0.5rem;
    }
    @include media-breakpoint-up(lg) {
      margin-right: 0;
    }
    @include media-breakpoint-up(xl) {
      margin-right: 0.5rem;
    }
  }

  &.right {
    @include media-breakpoint-up(md) {
      margin-left: 0.5rem;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 0;
    }
    @include media-breakpoint-up(xl) {
      margin-left: 0.5rem;
    }  }
}