.mobile-spacer {
  margin-top: 5.3rem;
}

.nav-logo {
  cursor: pointer;
  max-width: 220px;
}

.mobile-nav-side {
  overflow: hidden;
  max-width: 0px;
  width: 0px;
  transition: 0.6s;
  position: fixed;
  background-color: white;
  white-space: nowrap;
  z-index: 14;

  @include media-breakpoint-up(lg) {
    max-width: 30%;
    min-width: 30%;
    width: 30%;
    transition: 0.6s;
    position: static;
  }

  @include media-breakpoint-up(xl) {
    max-width: 380px;
    min-width: 380px;
    width: 380px;
  }

  &.nav-show {
    max-width: 100%;
    width: 100%;
    transition: 1s;
    @include media-breakpoint-up(sm) {
      width: 380px;

    }
  }

  .mobile-nav-wrapper {
    @include media-breakpoint-up(lg) {
      position: fixed;
      max-width: 30%;
      min-width: 30%;
      width: 30%;
    }

    @include media-breakpoint-up(xl) {
      max-width: 380px;
      min-width: 380px;
      width: 380px;
    }
  }

  .sidebar-nav-break {
    opacity: 1;
    background-image: linear-gradient(
      to left,
      #ff5ea0 0%,
      #ff5ea0 45%,
      #425a95 45%,
      #425a95 55%,
      #67dae7 55%,
      #67dae7 100%
    );
  }

  .nav-footer-text {
    font-size: 14px;
    font-weight: 300;
  }
}

.mobile-navbar-top {
  z-index: 15;
  position: fixed;
}

.main-content-col {
  position: relative;

  max-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

  @include media-breakpoint-up(md) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  @include media-breakpoint-up(lg) {
    max-width: 56vw;
    margin-left: 5rem;
    margin-right: 5rem;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-left: 5rem;
    padding-right: 5rem;
    margin: 0;
  }
}

.breadcrumb-header {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(lg) {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

.cart-wrapper {
  position: fixed;
  top: 5.5rem;
  @media (max-width: 1920px) {
    right: 1rem;
  }
  @media (min-width: 1921px) {
    translate: (721px);
    &.new-order-offset {
        translate: (644px);
    }
  }


  padding: 1rem 1rem;
  z-index: 10;
  background-color: #ffffff;
  border-radius: 2rem;
  box-shadow: 0px 0px 29px 15px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 29px 15px rgba(0, 0, 0, 0.05);

  @include media-breakpoint-up(lg) {
    top: 1.5rem;
  }

  &.customer-center-padding {
    padding-left: 2rem;
  }

  .basket-counter-span {
    background: $cormie-red-gradient;
    width: 34px;
    height: 34px;
    line-height: 34px;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    z-index: 1 !important;

    transition: all 0.3s ease;
    &:hover {
      transition: all 0s;
      filter: brightness(0.85);
      cursor: pointer;
    }

    position: fixed;

    @include media-breakpoint-up(lg) {
      top: 35px !important;
    }

    @media (max-width: 1920px) {
      right: 32px;
    }
    @media (min-width: 1921px) {
      translate: (-17px);
      top: 10px !important;
    }


    top: 99px;

    font-weight: 700;
    color: #ffffff;
    z-index: -1;

  }

  .basket-icon {
    position: relative;
    top: 11px;
    right: -7px;
    z-index: 2;
  }
}



p.welcome-user-name {
  font-size: 24px;
  opacity: 0.8;
}
