.po-number-badge {
  background-color: $background-colour !important;
  border: 1px solid $faded-grey-border
}

.responsive-nowrap {
  @include media-breakpoint-up(md) {
    white-space: nowrap;
  }
  @include media-breakpoint-up(xl) {
    white-space: normal;
  }
}

.product-item-row {
  @include media-breakpoint-up(xl) {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }

  .detail-text {
    position: relative;
    max-width: 110px;
    margin-right: 6px;
  }

  .display-block-xxxl {
    @media (min-width: 1680px) {
      display: block !important;
    }
  }

  .product-item-col {
    background-color: $background-colour;
    border-radius: 10px;
    border: 1px solid $faded-grey-border;

    &.add-new-item-col {
      border: 2px dashed #E5E3E4 !important;
      transition: all 0.3s ease;
      min-height: 11rem;

      &:hover {
        cursor: pointer;
        background-color: rgba(249,250,249,0.5);
        transition: all 0s ;

        .row {
          transform: scale(1.05);
        }
      }
    }

    .permacart-item-product-details {
      .row {
        padding-bottom: 0.3rem;
      }
    }

    @include media-breakpoint-up(lg) {
    .product-img img {
      height: 160px;
    }
  }

    @include media-breakpoint-up(md) {
      margin-left: -0.25rem;
      margin-right: -0.25rem;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 0;
    }

    @include media-breakpoint-up(xl) {
      margin-left: -0.25rem;
      margin-right: -0.25rem;
    }

    @include media-breakpoint-up(xl) {
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }
  }
}

.select-gender {
display: contents;
}

@media (max-width: 768px) {
  .select-gender {
    font-size: 12px;
  }
}
